@import 'var';
@import 'mixins';

.seller-insights {
    background-color: $sellerThemeColor;
    min-height: 100vh;

    .seller-insights-container {
        min-height: 100vh;

        .row {
            min-height: 100vh;
            align-content: center;

            .youtube-video-container {
                overflow: hidden;
                aspect-ratio: 10/5.5;

                iframe {
                    width: 100%;
                    height: 100%;
                }
            }
            .seller-inghts-accordion{
                .accordion-button{
                    padding: .5rem .75rem;
                }
            }
        }
    }
}