@import 'var';
@import 'mixins';

.seller-product-home {
    .custom-product-home-btn {
        a {
            @include dark-sub-btn;
            background-color: $intensDarkColor;
            border: 3px solid $intensDarkColor;
            border-radius: .5rem;
            transition: .3s;

            &:hover {
                background-color: transparent;
                color: $intensDarkColor;
            }
        }
    }

    .custom-steps-banner {
        border: 1px solid $borderColor;
        border-radius: .75rem;
        box-shadow: inset 10px 10px 15px $lightColor, 10px 10px 15px $lightColor;

        .custom-step-items {
            .custom-img-container {
                width: 50%;
                aspect-ratio: 1/1;
                position: relative;

                img {
                    border-radius: .75rem;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .custom-top-steps {
                    position: absolute;
                    border-radius: 50%;
                    top: 0;
                    left: 0;
                    transform: translate(-25%, -25%);
                    background-color: rgba(255, 255, 255, 0.575);
                    @include flex-center-align;
                    height: 45px;
                    width: 45px;
                    overflow: hidden;
                    border: 4px solid $borderColor;
                    background-color: $secondaryColor;
                }
            }

            .custom-steps {
                position: relative;

                span {
                    font-weight: 700;
                    height: 45px;
                    width: 45px;
                    border-radius: 50%;
                    @include flex-center-align;
                    overflow: hidden;
                    border: 4px solid $borderColor;
                    background-color: $secondaryColor;
                }

                &::before {
                    z-index: -1;
                    content: '';
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 3px;
                    background-color: $borderColor;
                }
            }

            &:first-child .custom-steps::before {
                width: 50%;
                left: 50%;
            }

            &:last-child .custom-steps::before {
                width: 50%;
                left: 0;
            }
        }
    }

    .custom-category-banner {
        .custom-img-holder {
            width: 100%;
            aspect-ratio: 1/1;
            border-radius: .75rem;
            overflow: hidden;
            position: relative;
            border: 1px solid $borderColor;
            transition: .3s;

            div {
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: $darkColor;
                opacity: 0;
                transition: .3s;
            }

            &:hover img {
                transform: scale(1.1);
            }

            &:hover div {
                opacity: .35;
            }

            &:hover h6 {
                box-shadow: 0 0 15px $darkColor;
                text-decoration: underline;
                // background-color: rgba(255, 255, 255, 1);
            }

            img {
                transition: .3s;
                transform: scale(1);
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            h6 {
                position: absolute;
                color: $intensDarkColor;
                bottom: 10%;
                width: 100%;
                background-color: rgba(255, 255, 255, 0.5);
                left: 50%;
                transform: translateX(-50%);
                padding: .5rem;
                box-shadow: 0 0 5px $darkColor;
                text-align: center;
                transition: .3s;

                &::before {
                    z-index: -1;
                    content: '';
                    position: absolute;
                    bottom: 0%;
                    width: 100%;
                    height: 100%;
                    background-color: rgb(255, 255, 255);
                    left: 100%;
                    box-shadow: 0 0 5px $darkColor;
                    text-align: center;
                    transition: .6s;
                }
            }

            &:hover h6::before {
                left: 0;
            }
        }
    }

    .custom-table-responsive {
        .img-holder {
            width: 100%;
            aspect-ratio: 1/1;
            overflow: hidden;
            border-radius: .25rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .dropdown {
            .btn-primary {
                background-color: transparent;
                border: 0px;
                color: $primaryColor;
            }

            .dropdown-toggle::after {
                display: none;
            }

            .dropdown-item.active,
            .dropdown-item:active {
                background-color: transparent;
                color: $primaryColor;
            }
        }
    }
}

@media (min-width: 992px) {
    .seller-product-home {
        .custom-table-responsive {
            .img-holder {
                max-width: 100px;
                aspect-ratio: 1/1;
                overflow: hidden;
                border-radius: .25rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .custom-icon-list {
                span {
                    height: 35px;
                    width: 35px;
                    @include flex-center-align;
                    border-radius: .25rem;
                    cursor: pointer;
                    transition: .3s;

                    &:hover {
                        background-color: $borderColor;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .custom-product-item {
        border: 1px solid $borderColor;
        border-radius: .25rem;
    }

    .custom-table-responsive {
        position: relative;

        .product-status-label {
            position: absolute;
            top: 10px;
            left: 10px;
        }
    }

    .custom-icon-list {
        width: 100%;
    }

    .seller-product-home {
        .custom-table-responsive {
            .img-holder {
                width: auto;
            }
        }
    }
}

.ui-checkbox {
    --primary-color: #04ac84;
    --secondary-color: #fff;
    --primary-hover-color: #39aa90;
    /* checkbox */
    --checkbox-diameter: 25px;
    --checkbox-border-radius: 5px;
    --checkbox-border-color: #d9d9d9;
    --checkbox-border-width: 1px;
    --checkbox-border-style: solid;
    /* checkmark */
    --checkmark-size: 1.5;
}

@media (max-width: 576px) {
    .check-holder {
        .ui-checkbox {
            --checkbox-diameter: 20px;
        }
    }
}

/* checkbox settings 👇 */
.check-holder {

    .ui-checkbox,
    .ui-checkbox *,
    .ui-checkbox *::before,
    .ui-checkbox *::after {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .ui-checkbox {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: var(--checkbox-diameter);
        height: var(--checkbox-diameter);
        border-radius: var(--checkbox-border-radius);
        background: var(--secondary-color);
        border: var(--checkbox-border-width) var(--checkbox-border-style) var(--checkbox-border-color);
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        cursor: pointer;
        position: relative;
    }

    .ui-checkbox::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
        box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
        border-radius: inherit;
        opacity: 0;
        -webkit-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
        -o-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
        transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    }

    .ui-checkbox::before {
        top: 40%;
        left: 50%;
        content: "";
        position: absolute;
        width: 4px;
        height: 7px;
        border-right: 2px solid var(--secondary-color);
        border-bottom: 2px solid var(--secondary-color);
        -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(0);
        -ms-transform: translate(-50%, -50%) rotate(45deg) scale(0);
        transform: translate(-50%, -50%) rotate(45deg) scale(0);
        opacity: 0;
        -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
        -o-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
        transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    }

    /* actions */

    .ui-checkbox:hover {
        border-color: var(--primary-color);
    }

    .ui-checkbox:checked {
        background: var(--primary-color);
        border-color: transparent;
    }

    .ui-checkbox:checked::before {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
        -ms-transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
        transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
        -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        -o-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    }

    .ui-checkbox:active:not(:checked)::after {
        -webkit-transition: none;
        -o-transition: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        transition: none;
        opacity: 1;
    }
}

.check-holder-free {

    .ui-checkbox,
    .ui-checkbox *,
    .ui-checkbox *::before,
    .ui-checkbox *::after {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .ui-checkbox {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: var(--checkbox-diameter);
        height: var(--checkbox-diameter);
        border-radius: var(--checkbox-border-radius);
        background: var(--secondary-color);
        border: var(--checkbox-border-width) var(--checkbox-border-style) var(--checkbox-border-color);
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        cursor: pointer;
        position: relative;
    }

    .ui-checkbox::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
        box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
        border-radius: inherit;
        opacity: 0;
        -webkit-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
        -o-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
        transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    }

    .ui-checkbox::before {
        top: 40%;
        left: 50%;
        content: "";
        position: absolute;
        width: 4px;
        height: 7px;
        border-right: 2px solid black;
        border-bottom: 2px solid black;
        -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(0);
        -ms-transform: translate(-50%, -50%) rotate(45deg) scale(0);
        transform: translate(-50%, -50%) rotate(45deg) scale(0);
        opacity: 0;
        -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
        -o-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
        transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    }

    /* actions */

    .ui-checkbox:hover {
        border-color: var(--primary-color);
    }

    .ui-checkbox:checked {
        background: transparent;
        border-color: transparent;
    }

    .ui-checkbox:checked::before {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
        -ms-transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
        transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
        -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        -o-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    }

    .ui-checkbox:active:not(:checked)::after {
        -webkit-transition: none;
        -o-transition: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        transition: none;
        opacity: 1;
    }
}

@media (max-width: 992px) {
    .product-top-corner-check {
        position: absolute;
        bottom: 10px;
        left: 10px;
    }

    .product-top-corner-check,
    .check-holder {
        .ui-checkbox {
            --checkbox-diameter: 25px;
            --checkbox-border-radius: 5px;
            --checkmark-size: 1.7;
        }
    }
}