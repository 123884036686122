$themeColor: black;
$newThemeColor: rgb(240, 240, 240);
$subThemeColor: #F8F8F8;
$petaThemeColor: #E8E5E0;
$sellerThemeColor: #f7f7f7;
$tempColor: #04ac84;
$petaTempColor: rgb(241, 226, 222);
$petaTempBgColor: #F5EEE9;

$primaryColor: black;
$secondaryColor: #FFFFFF;
$subSecondaryColor: #04ac84;
$lightSubSecondaryColor: #E4ECF4;
$lightColor: lightgray;
$paragraphColor: #485256;
$darkSellerColor: #24292B;
$darkColor: black;
$darkSubColor: #04ac84;
$intensDarkColor: #04ac84;
$borderColor: #e3e4e5;
$tempBorderColor: rgba(131, 131, 131, 0.25);
$sliderBGColor: rgb(0, 195, 255);
$bannerBGcolor: #B7E2E4;

$infoColor: #162B9A;


// ==================================================
// Default Variables
// ==================================================

@use "sass:map";
@use "sass:list";

$colors-levels: (
    25,
    50,
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    800,
    900
);

// Default list
@function get_accent($type: "accent") {
    $accent: (
        "primary": var(--color-primary),
        "secondary": var(--color-secondary),
        "success": var(--color-success),
        "warning": var(--color-warning),
        "danger": var(--color-danger),
        "muted": var(--color-gray-400),
    );

@if ($type !='accent') {
    @each $key, $value in $accent {
        $accent-key: $key;
        $accent: map.merge($accent, ($key: var(--color-#{$key}),
                ));

        @if ($type =='button') {
            $hover-value: $value;

            @if type-of($value)=='color' {
                $hover-value: var(--color-#{$key}-600);
            }

            @if type-of($value)=='string' {
                $hover-value: var(--color-#{$accent-key}-600);
            }

            $accent: map.merge($accent, (#{$key}-hover: $hover-value ));
        }
    }
}

@return $accent;
}

$elements: (
    'button',
    'form',
    'card',
    'background',
    'text',
    'label',
    'alert'
);
$elements-accents: (
);

@each $type in $elements {
    $elements-accents: map.merge($elements-accents, ($type: get_accent($type)));
}