@import 'var';
@import 'mixins';

.seller-return-order {
    background-color: $sellerThemeColor;

    .seller-return-order-main-container {

        .custom-dropdown-one {
            @include lightBtn;
            border: 1px solid $themeColor;
            transition: .3s;

            &:hover {
                color: $darkSubColor;
            }
        }

        .custom-dropdown-two {
            @include dark-sub-btn;
            border: 1px solid $darkSubColor;
            transition: .3s;

            &:hover {
                background-color: $intensDarkColor;
            }
        }

        .seller-return-order-container {
            @include seller-box;
            @include custom-scrollbar;

            ::-webkit-scrollbar {
                height: 2px;
                background-color: rgb(221, 221, 221);
            }

            ::-webkit-scrollbar-thumb {
                background: rgb(59, 59, 59);
            }

            .nav-link {
                color: $darkSubColor;
                font-weight: 600;
                border-radius: .375rem .375rem 0 0;
                font-size: 14px;
            }

            .nav-pills .nav-link.active,
            .nav-pills .show>.nav-link {
                background-color: transparent !important;
                border-bottom: 4px solid $darkSubColor;
            }

            .custom-tabs {
                overflow-x: auto;
                overflow-y: hidden;
                white-space: nowrap;
                display: flex;
                justify-content: start;
                flex-wrap: nowrap;
                padding-top: 5px;
                border-bottom: 1px solid $borderColor;

                .nav-item,
                &>.nav-link {
                    flex: none;
                }

                .nav-item {
                    display: inline-block;
                }
            }

            .nav-tabs {
                border-bottom: 1px solid $lightColor;

                .nav-link {
                    color: $darkSubColor;
                    font-weight: 600;

                    &.active {
                        background-color: $darkSubColor;
                        color: $secondaryColor;
                    }
                }
            }


        }

        .custom-order-list {
            @include seller-box;

            .custom-order-list-filter-bar {
                border-bottom: 1px solid $borderColor;

                .custom-check-box-container {
                    input {
                        height: 25px;
                        width: 25px;
                        cursor: pointer;

                        &:focus {
                            outline: 0;
                        }
                    }
                }
            }

            .custom-order-list-bar-item {
                border-bottom: 1px solid $borderColor;
                // overflow: hidden;

                &:hover {
                    background-color: $sellerThemeColor;
                }

                @media(min-width: 768px) {
                    &:hover .custom-status-bar-content {
                        display: none;
                    }

                    &:hover .custom-status-bar-btn {
                        display: block !important;
                    }

                    .custom-status-bar-btn {
                        display: none !important;
                    }
                }

                .custom-status-bar-btn {

                    button {
                        @include dark-sub-btn;
                        border: 2px solid $darkSubColor;
                        border-radius: .5rem;
                        transition: .3s;

                        &:hover {
                            @include lightBtn;
                            color: $darkSubColor;
                            border: 2px solid $darkSubColor;
                        }
                    }
                }

                .custom-check-box-container {
                    input {
                        height: 25px;
                        width: 25px;
                        cursor: pointer;

                        &:focus {
                            outline: 0;
                        }
                    }
                }

                .custom-status-bar-container {
                    .custom-status-bar {
                        span {
                            height: 12px;
                            width: 12px;
                            border-radius: 50%;
                            overflow: hidden;
                            outline: 1px solid red;
                            outline-offset: 2px;
                            background-color: red;
                        }
                    }
                }

                &:last-child {
                    border-bottom: 0px solid $borderColor;
                    margin-bottom: 0 !important;
                }
            }
        }

        .custom-transaction-tab {

            svg {
                font-size: 100px;
                color: $darkSubColor;
            }
        }

        @media(max-width: 768px) {
            .custom-transaction-tab {

                svg {
                    font-size: 50px;
                    color: $darkSubColor;
                }
            }
        }
    }

    .custom-select-option {
        .css-13cymwt-control {
            height: 50px;
        }

        .css-3w2yfm-ValueContainer {
            height: 50px;
            overflow-y: scroll;
            overflow-x: hidden
        }
    }
}

.custom-btn-scrollbar {
    ::-webkit-scrollbar {
        background-color: transparent;
    }

    .custom-filter-btns {
        overflow-x: scroll;
        overflow-y: hidden;

        button {
            @include seller-box;
            border-radius: .25rem;
            @include flex-between-align;
        }
    }
}

@media(min-width: 600px) {
    .seller-return-order {
        .seller-return-order-main-container {
            .seller-return-order-container {
                .custom-tabs-with-even {
                    .nav-item {
                        width: 25%;

                        button {

                            &:active,
                            &:focus {
                                outline: 0px;
                                box-shadow: 0 0 0 transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media(max-width: 768px) {
    .custom-select-container {
        .custom-select-calendar {
            right: auto;
            left: 0;

            .rdrStaticRanges {
                display: grid;
            }

            .rdrStaticRangeLabel {
                padding: 5px 10px;
            }

            .rdrDefinedRangesWrapper {
                width: 100px;
            }

            .rdrInputRange {
                padding: 5px 10px;
            }

            .rdrMonth {
                padding: .5rem;
                padding-top: 0px;
            }

            .rdrStaticRange,
            .rdrDefinedRangesWrapper {
                font-size: 10px;
            }

            .rdrDay {
                line-height: 1.5rem;
                height: 1.5rem;
            }

            .rdrCalendarWrapper {
                font-size: 9px;
            }

            .rdrMonth {
                width: 175px;
            }

            .rdrDayNumber span {
                &:after {
                    background-color: transparent !important;
                }
            }

            .rdrNextPrevButton {
                height: 1rem;
                width: 1rem;
            }

            .rdrNextButton i {
                margin: 0;
            }

            .rdrMonthAndYearWrapper {
                justify-content: start;
            }

            .rdrMonthAndYearPickers select {
                padding: 10px 20px 10px 0px;
            }
        }
    }
}

@media(max-width: 576px) {
    .custom-dropdown-one {

        &::after {
            display: none !important;
        }
    }
}

@media(max-width: 400px) {
    .seller-return-order {

        .seller-return-order-main-container {

            .seller-return-order-container {

                .custom-tabs {
                    .nav-item {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

// SellerSingleOrderPage.jsx
.custom-single-order-container {
    background-color: $sellerThemeColor;
    min-height: 100vh;

    .custom-single-order {
        .custom-single-order-back-link {
            @include back-btn;
        }

        .custom-order-header-btn {
            a {
                @include lightBtn;
                font-weight: 600;
                transition: .3s;
                border-radius: .5rem;
                border: 2px solid transparent;
                cursor: pointer;

                &:hover {
                    @include dark-sub-btn;
                }

                &:first-child {
                    &:hover {
                        @include lightBtn;
                        color: $tempColor;
                        border: 2px solid $tempColor;
                    }
                }
            }
        }

        .custom-order-product-img {
            width: 75px;
            aspect-ratio: 1/1;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .custom-order-status-bar {
            background-color: $lightColor;
            border-radius: 2rem;

            .custom-order-status-bar-div {
                padding: .5rem 0;
                padding-left: 0.5rem;
                position: relative;

                svg {
                    border-radius: 50%;
                    color: $themeColor;
                    background-color: $secondaryColor;
                    padding: .25rem;
                    font-size: 20px;
                }

                section {
                    position: absolute;
                    top: 120%;
                    left: 6%;
                    color: $themeColor;
                }

                &:ld {
                    padding-right: 0.5rem;
                }
            }

            .custom-bg-info:first-child {
                border-radius: 2rem 0 0 2rem;
            }

            .last-child-style {
                border-radius: 0 2rem 2rem 0;
            }

        }

        .timeline {
            display: flex;
            flex-direction: column;
            position: relative;
            margin: 20px 0;

            .timeline-last-item {
                &:before {
                    height: 0% !important;
                }
            }

            .timeline-item {
                display: flex;
                align-items: flex-start;
                position: relative;
                margin-bottom: 12px;
                overflow-y: hidden;
                overflow-x: visible;

                &:before {
                    content: '';
                    position: absolute;
                    left: 10px;
                    top: 30px;
                    bottom: 0;
                    height: 100%;
                    width: 2px;
                    background: $paragraphColor;
                }

                .timeline-icon {
                    position: relative;
                    z-index: 1;
                    flex-shrink: 0;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background: $darkSubColor;
                    outline: 1px solid $darkSubColor;
                    outline-offset: 2px;
                }

                .circle-one {
                    background-color: red;
                    outline-color: red;
                }

                .circle-two {
                    background-color: blue; // Adjust to your desired color
                    outline-color: blue;
                }

                .circle-three {
                    background-color: gray; // Adjust to your desired color
                    outline-color: gray;
                }

                .timeline-content {
                    padding-left: 20px;

                    .timestamp {
                        display: block;
                        font-size: 14px;
                        color: $paragraphColor; // Adjust to your desired color
                    }

                    .description {
                        display: block;
                        font-size: 14px;
                        color: $paragraphColor; // Adjust to your desired color
                    }

                    b {
                        font-weight: bold;
                    }
                }
            }

            .timeline-item.lastItem {
                &::before {
                    background: transparent;
                }
            }

        }



    }
}

@media(max-width: 576px) {
    .custom-single-order-container {
        background-color: $sellerThemeColor;

        .custom-single-order {

            .custom-order-status-bar {
                width: 37px;
                overflow: visible;

                .custom-order-status-bar-div {
                    position: relative;
                    overflow: visible;
                    border-radius: 0;
                    padding: .75rem 0.5rem !important;

                    &:first-child {
                        padding-top: 1rem !important;
                    }

                    section {
                        width: 100px;
                        position: absolute;
                        top: 60%;
                        left: 120%;
                        color: $themeColor;
                    }

                    span {
                        width: 100px;
                        position: absolute;
                        top: 20%;
                        left: 120%;
                        color: black !important;
                    }

                    &:last-child {
                        padding-right: 0.5rem;
                    }
                }

                .custom-bg-info:first-child {
                    border-radius: 2rem 2rem 0 0;
                }

                .last-child-style {
                    border-radius: 0 0 2rem 2rem;
                }
            }
        }
    }
}

.custom-price-list-table {
    @include custom-scrollbar;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        height: 10px;
    }

    div {
        overflow-x: scroll;
        display: grid;
        overflow-y: hidden;

        table {
            border-collapse: collapse;
            border-radius: .5rem .5rem 0 0;
            overflow: hidden;

            th,
            td {
                border: 1px solid $intensDarkColor;
                text-align: left;
                background-color: transparent;
                padding: 4px 0px;
            }

            th {
                padding: 8px;
            }

            thead {
                th {
                    background-color: $darkSubColor;
                    color: $secondaryColor;
                }
            }
        }

    }
}

.custom-order-dropdown {
    .dropdown {
        .btn-primary {
            // background-color: transparent;
            border: 0px;
            // color: $primaryColor;
        }

        .dropdown-toggle::after {
            display: none;
        }

        .dropdown-item.active {
            background-color: transparent;
            color: $primaryColor;
        }
    }

    .more-btn {
        height: 35px;
        width: 35px;
        @include flex-center-align;
        border-radius: .5rem;
        background-color: $tempColor;
        color: $secondaryColor;
    }

    color: rgb(243, 219, 219);
}

// .custom-tabs-with-even {}

.custom-img-holder-container {
    .custom-img-holder {
        background-color: var(--imgBack);
        width: 100%;
        aspect-ratio: 1/1;
        overflow: hidden;
        border-radius: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 100%;
            min-width: 100%;
            object-fit: cover;
        }

        .img-one {
            min-width: 100%;
            height: 100%;
            transition: .45s;
        }

        .img-two {
            min-width: 0% !important;
            height: 0% !important;
            transition: .45s !important;
            opacity: 0 !important;
        }

        &:hover .img-one {
            opacity: 0;
            min-width: 0%;
            height: 0%;
        }

        &:hover .img-two {
            min-width: 100% !important;
            height: 100% !important;
            opacity: 1 !important;
        }
    }

    .custom-img-cnt {
        color: var(--conTextColor);

        a {
            color: var(--conTextColor);
            transition: .3s;
            text-decoration: none;

            &:hover {
                color: $tempColor;
                text-decoration: underline;
            }
        }
    }

}

.custom-single-order-back-link {
    @include back-btn;
}

.custom-single-order-back-link-header {
    @include back-btn;
    color: $secondaryColor;

    &:hover {
        background-color: $secondaryColor;
        color: $tempColor;
    }
}

.custom-product-page {

    .vertical-slider {
        background-color: $secondaryColor;

        .slick-slider {
            width: 100%;
            aspect-ratio: 2/10;
        }

        .img-holder {
            width: 100%;
            aspect-ratio: 1/1;
            overflow: hidden;
            cursor: pointer;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &:hover .slick-prev {
            opacity: .75;
        }

        &:hover .slick-next {
            opacity: .75;
        }


        .slick-prev {
            top: 0;
            left: 50%;
            transform: translate(-50%);
            z-index: 999;
            width: 100%;
            height: 25px;
            background-color: var(--sellerColor);
            overflow: hidden;
            opacity: 0;
            transition: .3s;

            &:hover {
                opacity: 1;
            }

            &::before {
                content: '';
                display: block;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-up-fill' viewBox='0 0 16 16'%3E%3Cpath d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/%3E%3C/svg%3E");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                height: 25px;
                width: 25px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

            }
        }

        .slick-next {
            top: 100%;
            left: 50%;
            transform: translate(-50%, -100%);
            z-index: 999;
            width: 100%;
            height: 25px;
            background-color: var(--sellerColor);
            overflow: hidden;
            opacity: 0;
            transition: .3s;

            &:hover {
                opacity: 1;
            }

            &::before {
                content: '';
                display: block;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                height: 25px;
                width: 25px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

            }
        }
    }

    .single-slider {
        background-color: $secondaryColor;

        .slider-container {
            height: 100%;

            .slick-slider {
                height: 100%;

                .slick-list {
                    height: 100%;

                    .slick-track {
                        height: 100%;

                        div {
                            height: 100%;
                        }
                    }
                }
            }
        }

        .img-holder {
            width: 100%;
            height: 100%;
            overflow: hidden;
            @include flex-center-align;
            border-radius: .5rem;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .slick-prev {
            display: none !important;
        }

        .slick-next {
            display: none !important;
        }
    }

    .single-big-slider {
        background-color: $secondaryColor;

        // .slider-container {
        //     height: 100%;

        //     .slick-slider {
        //         height: 100%;

        //         .slick-list {
        //             height: 100%;

        //             .slick-track {
        //                 height: 100%;

        //                 div {
        //                     height: 100%;
        //                 }
        //             }
        //         }
        //     }
        // }

        .slick-slide>div {
            padding: 0 5px;
        }

        .slick-list {
            margin: 0 -5px;
        }

        .img-holder {
            width: 100%;
            aspect-ratio: 1/1;
            overflow: hidden;
            @include flex-center-align;
            // border-radius: .5rem;
            border: 1px solid $borderColor;

            img {
                height: 100% !important;
                width: calc(100%) !important;
                object-fit: cover;
            }
        }

        .slick-prev {
            display: none !important;
        }

        .slick-next {
            display: none !important;
        }
    }

    .custom-page-content {
        .custom-page-title {

            i {
                border-radius: 50%;
                transition: .3s;
                cursor: pointer;

                &:hover {
                    background-color: rgba(128, 128, 128, 0.5);
                }
            }

            .dropdown-toggle {
                background-color: transparent;
                border: 0px;

                i {
                    color: var(--conTextColor);
                }

                &::after {
                    display: none;
                }
            }

            .custom-color {
                p {
                    color: var(--conTextColor);
                }

                .custom-color-list {
                    flex-wrap: wrap;

                    span {
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;
                        border: 2px solid $themeColor;
                        cursor: pointer;
                        transition: .3s;

                        &:hover {
                            border: 5px solid $themeColor;
                            border-color: $secondaryColor $themeColor $secondaryColor $themeColor;
                            outline: 1px solid $themeColor;
                        }
                    }

                    .active {
                        border: 5px solid $themeColor;
                        border-color: $secondaryColor $themeColor $secondaryColor $themeColor;
                        outline: 1px solid $themeColor;
                    }
                }
            }

            .custom-size {
                p {
                    color: var(--conTextColor);
                }

                .custom-size-list {
                    flex-wrap: wrap;

                    span {
                        padding: .33rem 1.5rem;
                        border-radius: .25rem;
                        border: 2px solid $themeColor;
                        cursor: pointer;
                        color: var(--conTextColor);
                        transition: .3s;

                        &:hover {
                            background-color: $themeColor;
                            color: $secondaryColor;
                            border: 2px solid $themeColor;
                        }
                    }

                    .active {
                        background-color: $themeColor;
                        color: $secondaryColor;
                        border: 2px solid $themeColor;

                    }
                }

                .custom-qty-list {
                    &:first-child button {
                        border-radius: .33rem 0 0 .33rem;
                    }

                    &:last-child button {
                        border-radius: 0 .33rem .33rem 0;
                    }

                    button,
                    input {
                        width: 50px;
                        height: 50px;
                        border: 1px solid var(--sellerColor);
                        color: var(--conTextColor);
                        font-size: 22px;
                        background-color: transparent;
                        text-align: center;

                        &:focus {
                            outline: 0;
                        }

                        i {
                            background-color: transparent !important;
                        }

                        &:first-child {
                            border-radius: .33rem 0 0 .33rem;
                            border-right: 0;
                        }

                        &:last-child {
                            border-radius: 0 .33rem .33rem 0;
                            border-left: 0;
                        }
                    }

                    input {
                        font-size: 18px;
                    }
                }
            }

            .custom-cart-btn {
                button {
                    border: 0px;
                    padding: .65rem 1rem;
                    background-color: $themeColor;
                    border-radius: .25rem;
                    color: $secondaryColor;
                    transition: .3s;
                    text-transform: uppercase;
                    text-align: center;
                    width: 100%;
                    font-size: 14px;

                    &:last-child {
                        background-color: $tempColor;
                    }

                    &:hover {
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {

    .vertical-slider,
    .single-slider {
        display: none;
    }

}

.custom-create-order-accordion {
    .accordion-button:focus {
        box-shadow: 0 0 0 transparent;
    }

    .accordion-button:not(.collapsed) {
        background-color: transparent;
        color: $themeColor;
    }
}

.custom-user-list {
    button {
        @include lightBtn;
        border: 1px solid $borderColor;
        transition: .3s;
        color: black;

        i {
            width: 0;
            transform: scale(0);
            transition: .3s;
        }

        span {
            transition: .3s;
        }

        &:hover span {
            width: auto;
            transform: scale(.9);
        }
    }

    .active {
        @include darkBtn;
        background-color: $tempColor;
        border-radius: 3rem;
        color: white;
        border: 1px solid $tempColor;

        i {
            width: auto;
            transform: scale(1);
        }
    }
}

// .cartDetail {
//     .container {
//         max-width: 93% !important;
//     }
// }

.cartProductsize {
    a {
        color: var(--themeColor);
        text-decoration: none;
        font-size: 14px;
    }

    h5 {
        font-size: 18px;
    }
}

.qtyPlusMinuse {
    gap: 1rem;
    font-size: 14px;
    align-items: center;

    section {
        background-color: #F7F7F7;
        border-radius: 0.5rem;
    }

    div {
        align-items: center;
        background-color: #F7F7F7;
        border-radius: 0.5rem;

        input {
            border-radius: 0.5rem;
            border: 1px solid lightgray;
            width: 50px;
            text-align: center;
        }

        span {
            font-weight: 900;
            font-size: 17px;
            text-decoration: none;
            color: var(--themeColor);
            cursor: pointer;
        }
    }

}

.priceBox {
    border: 1px solid lightgray;
    border-radius: 0.5rem;
    background-color: rgba(235, 235, 235, 0.5)
}

.promoCodeInput {
    input {
        border-radius: 0.5rem 0 0 0.5rem;
    }

    button {
        border-radius: 0 0.5rem 0.5rem 0;
        @include darkBtn;
        transition: .3s;

        &:hover {
            @include lightBtn;
            border-color: var(--themeColor);
        }
    }
}

.coupenCodeDesign {
    border: 1px solid lightgray;

    div {
        section {
            border: 1px dashed var(--themeColor);
        }

        .base-btn {
            @include darkBtn;
            transition: .3s;
            border: 1px solid var(--themeColor);
            @include flex-center-align;

            &:hover {
                @include lightBtn;
                background-color: transparent;
                border-color: var(--themeColor);
            }

            i {
                transform: scale(0);
                transition: .3s;
                width: 0;
            }
        }

        .base-btn.active {
            letter-spacing: 1px;

            i {
                width: auto !important;
                transform: scale(1) !important;
            }

            border-radius: 3rem;
        }
    }
}

.finalCheckOutBtn {
    button {
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        @include darkBtn;
        transition: .3s;
        border: 1px solid var(--themeColor);
        letter-spacing: 1px;

        &:hover {
            @include lightBtn;
            background-color: transparent;
            border-color: var(--themeColor);
        }
    }

    div {
        max-height: 50px;
        display: flex;
        justify-content: center;

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }
}

.customHeartColor {
    a {
        color: var(--themeColor);
        transition: .3s;

        &:hover {
            color: var(--petaColor);
        }
    }
}


@media (max-width: 768px) {
    .cartProductsize {
        a {
            font-size: 12px;
        }

        h5 {
            font-size: 14px;
        }
    }

    .qtyPlusMinuse {
        font-size: 14px;
    }

    .footerForCart {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: var(--secondaryThemeColor);
        font-size: 12px !important;
        z-index: 99;

        h5 {
            font-size: 12px !important;
        }
    }
}


@media (max-width: 578px) {
    .qtyPlusMinuse {
        font-size: 12px;
        text-align: center;
        gap: 5px;

        div {
            justify-content: center;
        }
    }

    .cartItemPrd {
        border-top: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
    }
}

.cart-img-holder {
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.cart-card {
    border-radius: .33rem;
    padding: .5rem;
    border: 1px solid lightgray;
    background-color: rgba(235, 235, 235, 0.5)
}

.custom-checkout-page {
    .custom-checkout-page-steps {
        // min-height: 100vh;
        background-color: white;

        .custom-img-holder-container {
            a {
                color: black;
            }

            .custom-img-holder {
                height: 60px;
                aspect-ratio: 1/1;
                overflow: hidden;
                @include grid-center-align;
                border-radius: .5rem;

                img {
                    height: auto;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .custom-address-tab {
            @include custom-scrollbar;

            .custom-address-tab-container {
                max-height: 78vh;
                overflow-x: hidden;
                overflow-y: scroll;
                position: relative;


                .custom-address-list {
                    button {
                        @include lightBtn;
                        border: 0px;
                        transition: .3s;
                        color: black;

                        i {
                            width: 0;
                            transform: scale(0);
                            transition: .3s;
                        }

                        span {
                            transition: .3s;
                        }

                        &:hover span {
                            width: auto;
                            transform: scale(.9);
                        }
                    }

                    .active {
                        @include darkBtn;
                        background-color: black;
                        border-radius: 3rem;
                        color: white;

                        i {
                            width: auto;
                            transform: scale(1);
                        }
                    }
                }

                .custom-add-new-address {
                    .accordion-button {
                        box-shadow: 0 0 0 transparent;

                        &:focus {
                            box-shadow: 0 0 0 transparent;
                        }
                    }

                    .accordion-button:not(.collapsed) {
                        background-color: transparent;
                        color: black;
                    }
                }

                .custom-next-btn {
                    position: sticky;
                    bottom: 0;
                    display: grid;
                    background-color: white;

                    button {
                        padding: .5rem 1rem;
                        background-color: rgb(231, 231, 231);
                        color: grey;
                        border: 0px;
                        cursor: no-drop;
                        transition: .3s;
                    }

                    button.active {
                        background-color: black;
                        color: white;
                        cursor: pointer;
                        border-radius: 33rem;
                    }
                }


            }
        }

        @include custom-scrollbar;

        .custom-payment-tab {
            // height: 78vh;
            // overflow-x: hidden;
            // overflow-y: scroll;
            position: relative;

            .payment-list-item {
                background-color: rgb(233, 233, 233);
                border-radius: .1rem;
                align-items: center;
                cursor: pointer;
                border: 4px solid transparent;
            }

            .payment-list-item.active {
                background-color: rgb(97, 235, 127);
                border-radius: .33rem;
                border: 4px solid darkgreen;
            }

            .custom-final-btn {
                padding: .5rem 1rem;
            }

            .custom-final-btn {
                padding: .75rem 1rem;
                background-color: rgb(231, 231, 231);
                color: grey;
                border: 0px;
                cursor: no-drop;
                transition: .3s;
                text-decoration: none;
                letter-spacing: 1px;
            }

            .custom-final-btn.active {
                background-color: $tempColor;
                color: white;
                cursor: pointer;
                border-radius: 33rem;
                transition: .3s;

                &:hover {
                    letter-spacing: 2px;
                }
            }
        }
    }

    .custom-checkout-page-summary {
        background-color: lightgray;

        .order-product-list {
            display: flex;
            justify-content: space-between;

            .img-holder {
                width: 15%;
                aspect-ratio: 1/1;
                overflow: hidden;
                border-radius: .5rem;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .varient-list-data {
                span {
                    background-color: white;
                    border-radius: .33rem;
                }
            }

            span {
                background-color: white;
                border-radius: .33rem;
            }
        }
    }
}

@media(max-width: 400px) {
    .custom-checkout-page {
        .custom-checkout-page-steps {
            .custom-payment-tab {
                .payment-list-item {
                    .checkbox {
                        width: 25px;
                    }

                    .checkbox::before {
                        font-size: 30px;
                    }
                }
            }
        }

        .custom-checkout-page-summary {
            .order-product-list {
                .img-holder {
                    width: 30%;
                }
            }
        }

        background-color: #fff7f8;
    }
}

.main-custom-order-sub-category {
    .custom-order-sub-category {
        gap: 1.5px;
        background-color: $borderColor;
        border-radius: 5rem;
        border: 1px solid $borderColor;
        overflow: hidden;

        span:first-child {
            border-radius: 5rem 0 0 5rem;
        }

        span:last-child {
            border-radius: 0 5rem 5rem 0;
        }

        span {
            background-color: white;
            min-width: 120px;
            transition: .3s;
        }

        span.active {
            background-color: $darkSubColor;
            color: $petaTempBgColor;
        }
    }
}

@media(max-width: 768px) {
    .main-custom-order-sub-category {
        overflow-x: scroll;
        @include custom-scrollbar;

        ::-webkit-scrollbar {
            height: 3px;
            background-color: rgb(221, 221, 221);
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(59, 59, 59);
        }
    }
}

.custom-order-list-table-overflow {
    .custom-order-list-table-overflow-div {
        display: grid;

        .custom-order-list-table {
            border: 1px solid $borderColor;

            thead {
                background-color: #F8F9FA;
                border-bottom: 1px solid $borderColor;
            }

            th,
            td {
                padding: .75rem .5rem;
            }

            td {
                min-width: 85px;
            }

            tbody {
                tr {
                    border-bottom: 1px solid $borderColor;
                }
            }
        }
    }
}

@media(max-width: 576px) {
    .custom-order-list-table-overflow {
        @include custom-scrollbar;
        overflow-x: hidden;

        ::-webkit-scrollbar {
            height: 2px !important;
        }

        .custom-order-list-table-overflow-div {
            overflow-x: scroll;
            display: grid;
            overflow-y: hidden;

            ::-webkit-scrollbar {
                height: 2px;
            }

            .custom-order-list-table {
                overflow: hidden;

                th,
                td {
                    padding: .5rem .25rem;
                }
            }
        }
    }
}