@import 'var';
@import 'mixins';

.custom-single-product {
    .custom-slider-img-container {
        width: 100%;
        aspect-ratio: 1/1;
        overflow: hidden;
        cursor: pointer;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .product-custom-slick {
        .slick-slide {
            padding: 0 8px;
        }

        .slick-list {
            margin: 0 40px;
        }

        @include custom-slider-nav;

        .slick-prev,
        .slick-next {
            height: 95%;
            transform: translate(0%, 0%);
            top: 0%;
            border-radius: 0;
            transition: .3s;

            &::before {
                font-size: 30px;
                opacity: 1;
                transition: .3s;
                color: rgb(60, 60, 60);
            }

            &:focus {
                box-shadow: inset 0 0 10px rgb(60, 60, 60);
            }

            &:focus::before {
                font-size: 29px;
            }
        }

        .slick-prev {
            left: 0;
        }

    }

    .custom-accordian {
        .custom-accordian-bg {
            background-color: $sellerThemeColor !important;

            .custom-header {
                background-color: rgb(218, 218, 218);
            }
        }

        .accordion {
            border-radius: 0px;
            --bs-accordion-border-radius: 0px;

            .accordion-item {
                background-color: transparent;
            }
        }

        .accordion-button {
            padding-bottom: 0px;

            &::after {
                margin-left: auto;
                margin-right: auto;
                color: white;
            }
        }
    }

    .custom-color-balls-container {
        flex-wrap: wrap;

        .custom-color-balls {
            flex-wrap: wrap;
            width: 100%;

            span {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid $borderColor;
                background-color: red;
            }
        }
    }

    .custom-btns {
        a {
            @include dark-sub-btn;
            font-weight: 700;
            letter-spacing: 1px;
            border-radius: .25rem;
            border: 3px solid $darkSubColor;
            transition: .3s;

            &:hover {
                background-color: $intensDarkColor;
                border-color: $intensDarkColor;
            }
        }

        .custom-provider {
            background-color: transparent;
            color: $paragraphColor;
            border: 3px solid $paragraphColor;

            &:hover {
                color: $darkSubColor;
                border-color: $darkSubColor;
                background-color: transparent;
            }
        }
    }

    .custom-description-tabs {
        @include custom-scrollbar;

        ::-webkit-scrollbar {
            height: 2px;
            background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
            background: $lightColor;
        }

        .nav-tabs {
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            display: flex;
            flex-wrap: nowrap;
            padding-top: 5px;
        }

        .nav {
            @include flex-center-align;
        }

        .nav-tabs .nav-link {
            border: 0px;
            border-bottom: 3px solid transparent;
            transition: .3s;
            color: $darkColor;
            font-size: 25px;

            &:focus,
            &:hover,
            &:focus-visible {
                outline: 0;
                box-shadow: 0 0 0 transparent;
                border: 0px;
                border-bottom: 3px solid gray;
            }
        }


        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            border: 0px;
            border-bottom: 3px solid $darkColor !important;
            font-weight: 700;
        }

    }

    .size-tabs-container {
        @include custom-scrollbar;

        ::-webkit-scrollbar {
            height: 5px;
            background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: rgb(59, 59, 59);
        }

        .custom-size-tabs {
            overflow-x: scroll;


            .nav-tabs .nav-link {
                border: 0px;
                border-bottom: 3px solid transparent;
                transition: .3s;
                color: $darkColor;

                &:focus,
                &:hover,
                &:focus-visible {
                    outline: 0;
                    box-shadow: 0 0 0 transparent;
                    border: 0px;
                    border-bottom: 3px solid gray;
                }
            }

            .nav-tabs .nav-item.show .nav-link,
            .nav-tabs .nav-link.active {
                border: 0px;
                border-bottom: 3px solid $darkColor !important;
                font-weight: 700;
            }

            .table {
                border-collapse: collapse;

                th,
                td {
                    border: 1px solid black;
                    padding: 8px;
                    text-align: left;
                    background-color: transparent;
                }

                th {
                    background-color: $lightColor;
                }

            }

        }
    }

    .custom-rating-banner-relate {
        position: relative;
        cursor: pointer;

        .custom-rating-banner {
            cursor: default;
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(-110%);
            background-color: white;
            z-index: 100;
            min-width: 250px;
            box-shadow: 0 0px 15px $paragraphColor;
            border-radius: .25rem;

            &::before {
                position: absolute;
                content: '';
                bottom: 2px;
                left: 25px;
                transform: translateY(100%);
                height: 15px;
                width: 15px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 15px solid white;
                background-color: transparent !important;
                z-index: -1;
            }

            .custom-range {
                height: 6px;
                background-color: $lightColor;
                border-radius: 4px;
                overflow: hidden;

                .custom-range-color {
                    height: 100%;
                    left: 0;
                    background-color: $darkSubColor;
                }
            }

        }
    }
}

.custom-provider-tabs-container {
    @include custom-scrollbar;

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: rgb(59, 59, 59);
    }


    .custom-provider-tabs {
        height: 75vh;
        overflow-y: scroll;

        .nav-tabs .nav-link {
            border: 0px;
            border-bottom: 3px solid transparent;
            transition: .3s;
            color: $darkColor;

            &:focus,
            &:hover,
            &:focus-visible {
                outline: 0;
                box-shadow: 0 0 0 transparent;
                border: 0px;
                border-bottom: 3px solid gray;
            }
        }

        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            border: 0px;
            border-bottom: 3px solid $darkSubColor !important;
            font-weight: 700;
            color: $darkSubColor;
        }

        .table {
            border-collapse: collapse;

            th,
            td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
                background-color: transparent;
            }

            th {
                background-color: $lightColor;
            }

            tr:nth-child(even) {
                background-color: $lightColor;
            }
        }

        .custom-btns {
            a {
                @include lightBtn;
                border: 3px solid $darkColor;
                font-weight: 700;
                letter-spacing: 1px;
            }

            a.active {
                @include darkBtn;
            }
        }
    }

    .custom-balls {
        display: flex;
        flex-wrap: wrap;
        white-space: nowrap;

        span {
            height: 18px;
            width: 18px;
            border-radius: 50%;
            border: 1px solid $borderColor;
        }
    }
}

@media(max-width: 576px) {
    .custom-single-product {
        .product-custom-slick {
            .slick-slide {
                padding: 0 4px;
            }

            .slick-list {
                margin: 0 34px;
            }

            @include custom-slider-nav;

            .slick-prev {
                left: 0;
            }

            .slick-prev,
            .slick-next {
                height: 90%;
                transform: translate(0%, 0%);
                width: 30px;
                top: 0%;
                border-radius: 0;
                transition: .3s;

                &::before {
                    font-size: 15px;
                }

                &:focus {
                    box-shadow: inset 0 0 5px rgb(60, 60, 60);
                }

                &:focus::before {
                    font-size: 29px;
                }
            }
        }

        .custom-description-tabs {
            .nav-tabs .nav-link {
                font-size: 16px;
            }
        }
    }

    .custom-provider-tabs-container {
        .custom-provider-tabs {
            .nav-tabs .nav-link {
                font-size: 12px;
            }

            .table {

                th,
                td {
                    padding: 3px;
                }
            }
        }
    }

}