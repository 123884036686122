@import '_var';
@import '_mixins';

.custom-store-btn {
    @include dark-sub-btn;
    transition: .3s;
    border-radius: .25rem;
    border: 3px solid $intensDarkColor;
    letter-spacing: 1px;
    background-color: $intensDarkColor;

    &:hover {
        background-color: $intensDarkColor;
        border: 3px solid $intensDarkColor;
    }

    &.show {
        background-color: transparent;
        color: $intensDarkColor;
        border: 3px solid $intensDarkColor;
    }
}

.custom-seller-banner {
    max-height: 400px;
    overflow: hidden;

    .custom-seller-banner-gif {
        width: 30%;
        max-height: 100%;
        padding: 2rem;
        background-color: $intensDarkColor;
        display: grid;
        align-items: start;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .custom-seller-banner-content {
        width: 70%;

        a {
            text-shadow: 0 0 0px $secondaryColor;
        }

        div {
            a {
                @include dark-sub-btn;
                border-radius: .5rem;
                transition: .3s;
                background-color: $intensDarkColor;

                &:hover {
                    @include shadowLightBtn;
                }

                &:last-child {
                    @include shadowLightBtn;
                    border-radius: .5rem;
                    transition: .3s;

                    &:hover {
                        @include dark-sub-btn;
                        background-color: $intensDarkColor;
                    }
                }
            }


        }
    }
}

.logo-image-container {
    height: 70px;
    width: 70px;
    overflow: hidden;
    @include seller-box;
    border-radius: 50%;

    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

.custom-store-dtl-banner {
    .custom-store-dtl-banner-switch {
        @include seller-box;
        color: $themeColor;
        padding: .5rem 1rem;
        text-decoration: none;
        border-radius: .5rem;
        transition: .3s;

        &:hover {
            @include darkBtn;
            border: 1px solid $themeColor;
        }

        svg {
            transform: rotate(0deg);
            transition: .3s;
        }

        &:hover svg {
            transform: rotate(90deg);
        }
    }
}

.custom-order-tab-content {
    align-content: space-between;
    @include seller-box;

    .custom-order-tab-header {
        a {
            position: relative;

            .custom-order-tab-info {
                position: absolute;
                bottom: 0%;
                right: 0;
                transform: translate(0%, 100%);
                min-width: 160px;
                z-index: 999;
                padding: .35rem;
                line-height: 1rem;
                text-align: center;
                display: none;
                border-radius: .5rem;
                @include dark-sub-btn;
                font-size: 10px;
                cursor: default;
            }

            &:hover .custom-order-tab-info {
                display: block;
            }
        }
    }

    .custom-order-tab-sub-cnt {
        min-height: 120px;
        display: grid;
        align-content: space-between;

        div {
            a {
                @include seller-box;
                color: $themeColor;
                text-decoration: none;
                font-weight: 900;
                padding: .25rem .75rem;
                transition: .3s;
                letter-spacing: 1px;
                font-size: 12px;

                &:hover {
                    @include dark-sub-btn;
                    border-color: $darkSubColor;
                    padding: .25rem .75rem;
                }
            }
        }
    }
}

.custom-counting-part:nth-child(1) {
    .custom-order-tab-content {
        background-color: rgba(0, 0, 255, 0.25);
    }
}

.custom-counting-part:nth-child(2) {
    .custom-order-tab-content {
        background-color: rgba(0, 128, 0, 0.25);
    }
}

.custom-counting-part:nth-child(3) {
    .custom-order-tab-content {
        background-color: rgba(255, 0, 0, 0.25);
    }
}

.custom-counting-part:nth-child(4) {
    .custom-order-tab-content {
        background-color: rgba(255, 255, 0, 0.25);
    }
}


.custom-css-toggle {
    background-color: $themeColor !important;
    color: $secondaryColor !important;

    svg {
        transform: rotate(90deg) !important;
    }
}

.custom-setting-btn {
    a {
        display: flex;
        align-items: center;
        padding: .35rem;
        color: $themeColor;
        transition: .3s;
        border-radius: .5rem;

        &:hover {
            color: $secondaryColor;
            background-color: $themeColor;
        }
    }
}

.custom-select-store-modal {
    .modal-dialog {
        .modal-content {
            max-height: 90vh;
            @include custom-scrollbar;

            .modal-body {
                max-height: 100vh;
                overflow-y: scroll;
            }
        }
    }
}

.custom-store-modal {
    color: $themeColor;
    text-decoration: none;
    letter-spacing: 1px;
    transition: .3s;
    @include seller-box;

    &:hover {
        @include darkBtn;
        background-color: $tempColor;
        border-color: $tempColor !important;
    }
}

.custom-store-modal-selected {
    @include darkBtn;
    background-color: $tempColor;
    border-color: $tempColor !important;
}

.custom-image-container-modal {
    height: 2.5rem;
    width: 2.5rem;
    overflow: hidden;
    @include seller-box;
    border-radius: 50%;

    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

@media(max-width: 767px) {
    .custom-seller-banner {
        align-content: start;

        .custom-seller-banner-gif {
            max-height: auto;
            padding: 0rem;
            background-color: $darkColor;
            display: none;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }

        .custom-seller-banner-content {
            max-height: auto;
            width: 100%;

            .custom-display-none {
                display: none;
            }
        }
    }

}

@media (max-width: 576px) {

    .logo-image-container {
        height: 50px;
        width: 50px;
    }
}