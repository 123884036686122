@import 'var';
@import 'mixins';

.seller-wallet {
    background-color: $sellerThemeColor;

    .seller-wallet-main-container {
        min-height: 100vh;

        .seller-wallet-container {
            @include seller-box;
            @include custom-scrollbar;

            ::-webkit-scrollbar {
                height: 5px;
                background-color: transparent;
            }

            ::-webkit-scrollbar-thumb {
                background: rgb(59, 59, 59);
            }

            .custom-tabs {
                overflow-x: auto;
                overflow-y: hidden;
                white-space: nowrap;
                display: flex;
                flex-wrap: nowrap;
                padding-top: 5px;
            }

            .custom-tabs {
                .nav-item {
                    display: inline-block;
                }
            }

            .nav-tabs {
                border-bottom: 1px solid $lightColor;

                .nav-link {
                    color: $darkSubColor;
                    font-weight: 600;

                    &.active {
                        background-color: $darkSubColor;
                        color: $secondaryColor;
                    }
                }
            }

            .seller-wallet-payment {
                .row {
                    overflow: hidden;

                    .seller-wallet-payment-main {
                        background-color: $intensDarkColor;
                        color: $secondaryColor;
                        display: grid;

                        a {
                            @include lightBtn;
                            border: 2px solid $secondaryColor;
                            transition: .3s;
                            border-radius: .5rem;
                            color: $darkSubColor;

                            &:hover {
                                color: $secondaryColor;
                                background-color: transparent;
                            }
                        }
                    }

                    .seller-wallet-payment-sub {
                        background-color: $darkSubColor;
                        color: $secondaryColor;
                        justify-content: center;
                        text-align: center;
                        align-content: center;

                        .seller-wallet-payment-sub-img {
                            width: 3.5rem;
                            aspect-ratio: 1/1;
                            overflow: hidden;
                            border-radius: 50%;
                            background-color: $secondaryColor;

                            img {
                                min-width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }

            .seller-wallet-prm {
                a {
                    @include dark-sub-btn;
                    transition: .3s;
                    border-radius: .5rem;
                    letter-spacing: 1px;

                    &:hover {
                        background-color: $intensDarkColor;
                    }
                }
            }

            .seller-wallet-card {
                a {
                    color: $darkColor;
                    padding: 1rem;
                    transition: .3s;
                    border: 1px solid $lightColor;
                    letter-spacing: 1px;
                    text-decoration: none;

                    &:hover {
                        background-color: $sellerThemeColor;
                    }
                }
            }
        }

        .custom-transaction-tab {

            svg {
                font-size: 100px;
                color: $darkSubColor;
            }
        }



        .custom-reward-tab {

            svg {
                font-size: 100px;
                color: $darkSubColor;
            }
        }

        @media(max-width: 768px) {
            .custom-transaction-tab {

                svg {
                    font-size: 50px;
                    color: $darkSubColor;
                }
            }

            .custom-reward-tab {

                svg {
                    font-size: 50px;
                    color: $darkSubColor;
                }
            }
        }

        .custom-withdraw-tab-balance {
            border-radius: .5rem;
            background-color: $darkSubColor;
            color: $secondaryColor;

            .custom-rupee-input {
                div {
                    background-color: $secondaryColor;
                    color: $intensDarkColor;
                    @include seller-box;

                    input[type=number]::-webkit-inner-spin-button,
                    input[type=number]::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input {
                        background-color: transparent;
                        border: 0px solid transparent;
                        color: $intensDarkColor;

                        &:focus {
                            outline: 0;
                        }
                    }
                }
            }

            .custom-rupee-btn {
                @include lightBtn;
                color: $darkSubColor;
                border-radius: .5rem;
                border: 2px solid $secondaryColor;
                transition: .3s;

                &:hover {
                    @include dark-sub-btn;
                }
            }
        }

        .taxes-page {
            font-family: Arial, sans-serif;
            margin: 20px;


            h2 {
                color: $darkSubColor;
            }

            h3 {
                color: $themeColor;
            }

            h4 {
                color: $intensDarkColor;
            }

            p,
            ul {
                color: $paragraphColor;
                line-height: 1.6;
            }

            ul {
                padding-left: 20px;

                li,
                p {
                    font-size: 14px;
                }
            }


            ul ul {
                padding-left: 20px;
                list-style-type: circle;
            }
        }

    }


}

.custom-select-option {

    .dropdown-toggle {
        position: relative;

        &::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(-100%, -50%);
        }
    }

    .dropdown-menu {
        max-height: 200px;
        overflow-y: scroll;
    }

    .dropdown-item {
        &:active {
            color: $darkSubColor;
            background-color: transparent;
        }

        label {
            cursor: pointer;
        }

        input {
            height: 25px;
            width: 25px;
            cursor: pointer;

            &:focus {
                outline: 0;
            }
        }
    }
}

.custom-select-option-without-height {

    .btn-check:checked+.btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check)+.btn:active {
        background-color: transparent;
        border-radius: .5rem 0.5rem 0 0 !important;
        border-color: $borderColor;
    }

    .dropdown-toggle {
        position: relative;

        &::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(-100%, -50%);
        }
    }

    .dropdown-menu {
        border-radius: 0 0 .5rem 0.5rem;
        border-top-color: transparent !important;
        border-color: $borderColor;
        top: -2px !important;
    }

    .dropdown-item {

        &:active {
            color: $darkSubColor;
            background-color: transparent;
        }

        label {
            cursor: pointer;
            width: calc(100% - 25px);
        }

        input {
            height: 25px;
            width: 25px;
            cursor: pointer;
            border: 0px !important;

            &:focus {
                outline: 0;
            }
        }
    }
}

.custom-select-container {
    position: relative;

    .custom-select {
        @include seller-box;
        border-radius: 0;
        cursor: pointer;
        display: grid;
        align-content: center;
        color: $paragraphColor;
    }

    .custom-select-calendar {
        position: absolute;
        top: 100%;
        right: 0;
        @include seller-box;
        overflow: hidden;
        z-index: 999;

        .custom-close-btn {
            @include darkBtn;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 999;
            border: 2px solid $themeColor;
            border-radius: .45rem 0 .45rem 0;
            transition: .3s;
            letter-spacing: 1px;

            &:hover {
                @include lightBtn;
                border: 2px solid $themeColor;
            }

        }
    }
}

.custom-btn-scrollbar {
    ::-webkit-scrollbar {
        background-color: transparent !important;
    }

    .custom-filter-btns {
        overflow-x: scroll;
        overflow-y: hidden;

        button {
            @include seller-box;
            border-radius: .25rem;
            @include flex-between-align;
        }
    }
}

// Custom modal SCSS
.seller-wallet-card {
    @include custom-scrollbar;

    .custom-trial-modal {
        height: 80vh;
        overflow-y: scroll;
    }
}



@media(max-width: 992px) {
    .custom-select-option {
        .dropdown-item {
            input {
                height: 13px;
                width: 13px;
            }
        }
    }
}

@media(max-width: 768px) {
    .custom-select-container {
        .custom-select-calendar {
            right: auto;
            left: 0;

            .rdrStaticRanges {
                display: grid;
            }

            .rdrStaticRangeLabel {
                padding: 5px 10px;
            }

            .rdrDefinedRangesWrapper {
                width: 100px;
            }

            .rdrInputRange {
                padding: 5px 10px;
            }

            .rdrMonth {
                padding: .5rem;
                padding-top: 0px;
            }

            .rdrStaticRange,
            .rdrDefinedRangesWrapper {
                font-size: 10px;
            }

            .rdrDay {
                line-height: 1.5rem;
                height: 1.5rem;
            }

            .rdrCalendarWrapper {
                font-size: 9px;
            }

            .rdrMonth {
                width: 175px;
            }

            .rdrDayNumber span {
                &:after {
                    background-color: transparent !important;
                }
            }

            .rdrNextPrevButton {
                height: 1rem;
                width: 1rem;
            }

            .rdrNextButton i {
                margin: 0;
            }

            .rdrMonthAndYearWrapper {
                justify-content: start;
            }

            .rdrMonthAndYearPickers select {
                padding: 10px 20px 10px 0px;
            }
        }
    }
}

@media(max-width: 400px) {
    .seller-wallet {

        .seller-wallet-main-container {

            .seller-wallet-container {

                .custom-tabs {
                    .nav-item {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.custom-withdrawlist {
    border-radius: .33rem;
    overflow: hidden;
    border: 1px solid $borderColor;

    .custom-withdrawlist-item {
        hr {
            border-color: $borderColor;
        }
    }
}