@mixin flex-gap-one {
    display: flex;
    gap: 1rem;
}

@mixin flex-between {
    display: flex;
    justify-content: space-between;
}

@mixin flex-between-align {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flex-start-align {
    display: flex;
    justify-content: start;
    align-items: center;
}

@mixin flex-center-align {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin grid-center-align {
    display: grid;
    align-content: center;
    align-items: center;
}

@mixin lightBtn {
    background-color: $secondaryColor;
    color: $primaryColor;
    padding: 0.5rem 1rem;
    text-decoration: none;
}

@mixin darkBtn {
    background-color: $darkColor;
    color: $secondaryColor;
    padding: 0.5rem 1rem;
    text-decoration: none;
}

@mixin dark-sub-btn {
    background-color: $darkSubColor;
    color: $secondaryColor;
    padding: 0.5rem 1rem;
    text-decoration: none;
}

@mixin infoBtn {
    background-color: $subSecondaryColor;
    color: $secondaryColor;
    padding: 0.5rem 1rem;
    text-decoration: none;
    border: 0;
}

@mixin shadowLightBtn {
    background-color: transparent;
    color: $primaryColor;
    padding: .5rem 1rem;
    border: 0;
    box-shadow: 0 0 10px $lightColor;
    text-decoration: none;
}

@mixin themeBtn {
    background-color: $primaryColor;
    color: $secondaryColor;
    padding: 0.5rem 1rem;
    text-decoration: none;
    border: 0;
}

@mixin seller-box {
    background-color: $secondaryColor;
    border: 1px solid $borderColor;
    border-radius: .5rem;
}

@mixin user-box {
    background-color: $secondaryColor;
    border: 1px solid $lightColor;
    border-radius: .5rem;
}

@mixin custom-scrollbar {
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: darkgray;
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $themeColor;
    }
}

@mixin custom-slider-nav {

    .slick-prev,
    .slick-next {
        z-index: 99;
        height: 2.5rem;
        width: 2.5rem;
        border: 1px solid $lightColor;
        border-radius: 0.5rem;
        background-color: $secondaryColor;
        top: 0;

        &::before {
            color: $themeColor;
        }

        &::before :hover {
            opacity: 1;
            color: $darkColor !important;
        }

        &:hover {
            opacity: 1;
        }
    }

    .slick-prev {
        left: auto;
        right: 0%;
        transform: translate(-125%, -125%);
    }

    .slick-next {
        right: 0%;
        transform: translate(0%, -125%);
    }
}

@mixin custom-banner {
    .seller-category-slider-new-item {
        background-color: $intensDarkColor;
        color: $secondaryColor;
        border-radius: .5rem;
        overflow: hidden;

        .seller-category-slider-new-img {
            width: 35%;
            aspect-ratio: 3/2;
            overflow: hidden;
            display: flex;
            justify-content: center;

            img {
                min-width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .seller-category-slider-new-content {
            width: 65%;
            display: grid;
            align-content: center;

            .custom-design-btn {
                @include lightBtn;
                text-align: center;
                transition: .3s;
                border-radius: .5rem;
                letter-spacing: 2px;
                color: $intensDarkColor;
                border: 1px solid $secondaryColor;

                &:hover {
                    background-color: $intensDarkColor;
                    box-shadow: inset 0 0 5px $secondaryColor;
                    color: $secondaryColor;
                }
            }
        }
    }
}

@mixin img-holder-5-3 {
    width: 100%;
    aspect-ratio: 5/3;
    overflow: hidden;
    border-radius: .5rem;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

@mixin back-btn {
    @include flex-between-align;
    font-weight: 600;
    gap: .5rem;
    transition: .3s;
    padding: .5rem .25rem;
    color: $themeColor;
    text-decoration: none;

    &:hover {
        color: $darkSubColor;
        border-radius: 2rem;
        background-color: $lightColor;
        padding: .5rem 1rem;
    }
}

@mixin tempBtn {
    background-color: $tempColor;
    color: $secondaryColor;
    padding: 0.5rem 1rem;
    text-decoration: none;
    border: 0;
    transition: .3s;
    border-radius: .33rem;
}

@mixin petaTempBtn {
    background-color: $petaTempColor;
    color: $primaryColor;
    padding: 0.5rem 1rem;
    text-decoration: none;
    border: 0;
    transition: .3s;
    border-radius: .33rem;
}