@import "var";
@import "mixins";
@import "Signup";
@import "Signin";
@import "sellerSideBar";
@import "seller";
@import "sellerCreateStore";
@import "sellerDashboard";
@import "sellerCategory";
@import "sellerWallet";
@import "sellerOrders";
@import "sellerReturnOrders";
@import "common";
@import "sellerProductsListing";
@import "sellerInsights";
@import "sellerSubscription";
@import "sellerStoreListing";
@import "sellerAccount";
@import "ProductHome";
@import "sellerSingleProduct";
@import "productCanvasNew";
@import "sellerStoreSetting";
@import "sellerBranding";
@import "productCreate";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins";
  color: $primaryColor;
}

.flex-between {
  @include flex-between;
}

.flex-gap-one {
  @include flex-gap-one;
}

.no-border-rad {
  border-radius: 0rem !important;
}

.semi-border-rad {
  border-radius: 0.25rem !important;
}

.bi-border-rad {
  border-radius: 0.375rem !important;
}

.border-color {
  border-color: $borderColor !important;
}

.custom-bg-info {
  background-color: $darkSubColor;
}

.border-bottom {
  border-bottom: 1px solid $borderColor !important;
}

.half-border-rad {
  border-radius: 0.5rem !important;
}

.full-border-rad {
  border-radius: 1rem !important;
}

.temp-border-rad {
  border-radius: 50% !important;
}

.fw-900 {
  font-weight: 900;
}

.fs-45 {
  font-size: 45px;
}

.fs-40 {
  font-size: 40px;
}

.fs-35 {
  font-size: 35px;
}

.fs-30 {
  font-size: 30px;
}

.fs-25 {
  font-size: 25px;
}

.fs-23 {
  font-size: 23px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.fs-15 {
  font-size: 15px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-11 {
  font-size: 11px;
}

.fs-10 {
  font-size: 10px;
}

.fs-8 {
  font-size: 8px;
}

.txt-light {
  color: $lightColor;
}

.p-color {
  color: $paragraphColor;
}

.link-to-normal {
  color: $primaryColor;
  text-decoration: none;
}

.bg-home {
  background-color: $newThemeColor;
}

.shadow-light-btn {
  @include shadowLightBtn;
}

.custom-color-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  @include custom-scrollbar;
}

.info-btn {
  @include infoBtn;
}

.theme-btn {
  @include themeBtn;
}

.dark-btn {
  @include darkBtn;
}

.dark-sub-btn {
  @include dark-sub-btn;
}

.seller-box {
  @include seller-box;
}

.user-box {
  @include user-box;
}

.color-info {
  color: $subSecondaryColor;
}

.color-temp {
  color: $tempColor;
}

.cs-bc-item{
    a{
        text-decoration: none;
    }
}

.bg-temp {
  background-color: $tempColor !important;
  cursor: default !important;

  &:hover {
    background-color: $tempColor !important;
  }
}

.hover-increase {
  overflow: hidden;
  // transition: .3s;

  svg {
    transition: 0.3s;
  }

  &:hover svg {
    transform: scale(1.1);
  }
}

.custom-bg-temp {
  background-color: $tempColor;

  &:hover {
    background-color: $tempColor;
  }
}

.bg-petaTempBgColor {
  background-color: $petaTempBgColor;
}

.color-dark-info {
  color: $intensDarkColor;
}

.custom-transform {
  transform: translateY(25%);
}

.color-dark {
  color: $darkSubColor;
}

.color-dark-main {
  color: $darkColor;
}

.color-light-main {
  color: $secondaryColor;
}

.bg-light-main {
  background-color: $secondaryColor;
}

.flex-between-align {
  @include flex-between-align;
}

.flex-start-align {
  @include flex-start-align;
}

.flex-center-align {
  @include flex-center-align;
}

.no-border-width {
  border-width: 0px !important;
}

.border-red {
  border: 1px solid red !important;
}

.border-card {
  border: 1px solid $borderColor !important;
}

.bg-temp {
  background-color: $tempColor;
}

.width-fit {
  width: fit-content;
}

.temp-btn {
  @include lightBtn;
  color: $tempColor;
  border: 3px solid $tempColor;
  border-radius: 3rem;

  &:hover {
    background-color: $tempColor;
    border: 3px solid $secondaryColor;
  }
}

.custom-input-check-size {
  height: 22px;
  width: 22px;
}

.img-holder-5-3 {
  @include img-holder-5-3;
}

.pf-flex-row-reverse {
  flex-direction: row-reverse;
}

.custom-wrong-url {
  .custom-img-holder {
    height: 80vh;
    overflow: hidden;

    img {
      height: auto;
      width: 75%;
      object-fit: contain;
    }
  }

  a {
    @include dark-sub-btn;
    transition: 0.3s;
    border-radius: 0.33rem;
    background-color: #023475;
    border: 3px solid #023475;

    &:hover {
      background-color: transparent;
      color: #023475;
    }
  }
}

@media (max-width: 992px) {
  .fs-lg-25 {
    font-size: 25px;
  }

  .fs-lg-20 {
    font-size: 20px;
  }

  .fs-lg-18 {
    font-size: 18px;
  }

  .fs-lg-16 {
    font-size: 16px;
  }

  .fs-lg-14 {
    font-size: 14px;
  }

  .fs-lg-12 {
    font-size: 12px;
  }

  .pf-flex-lg-col-reverse {
    flex-direction: column-reverse;
  }

  .pf-flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
}

@media (max-width: 768px) {
  .fs-md-24 {
    font-size: 24px;
  }

  .fs-md-20 {
    font-size: 20px;
  }

  .fs-md-18 {
    font-size: 18px;
  }

  .fs-md-16 {
    font-size: 16px;
  }

  .fs-md-14 {
    font-size: 14px;
  }

  .fs-md-12 {
    font-size: 12px;
  }

  .fs-md-10 {
    font-size: 10px;
  }

  .pf-flex-md-col-reverse {
    flex-direction: column-reverse;
  }

  .pf-flex-md-row-reverse {
    flex-direction: row-reverse;
  }
}

@media (max-width: 576px) {
  .fs-sm-45 {
    font-size: 45px !important;
  }

  .fs-sm-25 {
    font-size: 25px;
  }

  .fs-sm-20 {
    font-size: 20px;
  }

  .fs-sm-18 {
    font-size: 18px;
  }

  .fs-sm-16 {
    font-size: 16px;
  }

  .fs-sm-14 {
    font-size: 14px;
  }

  .fs-sm-12 {
    font-size: 12px;
  }

  .fs-sm-10 {
    font-size: 10px;
  }

  .fs-sm-8 {
    font-size: 8px;
  }

  .pf-flex-sm-col-reverse {
    flex-direction: column-reverse;
  }

  .pf-flex-sm-row-reverse {
    flex-direction: row-reverse;
  }
}

@media (min-width: 1200px) {
  .cs-container-xl {
    max-width: 1200px !important;
  }

  .cs-xl-width {
    width: 1200px !important;
  }
}

@media (min-width: 992px) {
  .cs-container-lg {
    max-width: 992px !important;
  }

  .cs-lg-width {
    width: 992px !important;
  }
}

@media (min-width: 768px) {
  .cs-container-md {
    max-width: 768px;
  }
}

@media (min-width: 576px) {
  .cs-container-sm {
    max-width: 576px;
  }
}

// Image selector css
.custom-img-selector {
  input {
    display: none;
  }

  label {
    .custom-img {
      background-color: $lightColor;
      cursor: pointer;
      border-radius: 0.33rem;
      overflow: hidden;
      width: 100px;
      height: 100px;
      @include flex-center-align;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.text-orange {
  color: orange;
}

.custom-img-selector-lable {
  input {
    display: none;
  }

  div {
    background-color: $lightColor;
    cursor: pointer;
    border-radius: 0.33rem;
    overflow: hidden;
    width: 150px;
    height: 100px;
    @include flex-center-align;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.rsw-editor {
  border-radius: 0px !important;
  position: relative !important;
  height: 350px !important;
  overflow: unset !important;

  .rsw-toolbar {
    padding: 7.5px 0px !important;
    position: static !important;
    width: 100% !important;

    button {
      font-size: 16px;
      font-weight: bolder;
    }
  }

  .rsw-ce {
    margin-top: 0 !important;
    overflow: auto !important;
    height: calc(100% - 50px) !important;
  }
}

.custom-scrollbar {
  @include custom-scrollbar;

  ::-webkit-scrollbar {
    height: 7px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $tempColor;
  }
}

.cartesian {
  @include custom-scrollbar;

  tbody {
    tr.disabled {
      td,
      th {
        background-color: #00000013 !important;
      }
    }

    .slider {
      background-color: #ffffff2b;
      border-radius: 100px;
      padding: 1px;
      cursor: pointer;
      transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      align-items: center;
      position: relative;
      display: block;
      width: 40px;
      height: 20px;
      box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset,
        rgba(0, 0, 0, 0.21) 0px 0px 0px 24px inset,
        #22cc3f 0px 0px 0px 0px inset, rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
    }

    .slider::after {
      content: "";
      display: flex;
      top: 50%;
      transform: translateY(-50%);
      left: 3px;
      width: 15px;
      height: 15px;
      background-color: #e3e3e3;
      border-radius: 200px;
      position: absolute;
      box-shadow: transparent 0px 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 6px 6px;
      transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
        background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
      will-change: left, background-color;
    }

    .switch input[type="checkbox"]:checked + .slider {
      box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset,
        #383e9a 0px 0px 0px 2px inset, #373d99 0px 0px 0px 24px inset,
        rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
    }

    .switch input[type="checkbox"]:checked + .slider::after {
      left: 22px;
    }

    .switch input[type="checkbox"] {
      display: none;
    }
  }
}

.transactionTable {
  tbody {
    // tr.debit {
    //     td {
    //         background-color: #ff00008a;
    //     }
    // }
    // tr.credit {
    //     td {
    //         background-color: #3284329c;
    //     }
    // }
    height: auto;
  }
}

.canvas-bg-image {
  aspect-ratio: 1/1;

  img {
    object-fit: contain;
  }
}

.aspect-1-1 {
  aspect-ratio: 1/1;
}

.aspect-2-1 {
  aspect-ratio: 2/1;
}

.aspect-4-3 {
  aspect-ratio: 4/3;
}

.aspect-5-3 {
  aspect-ratio: 5/3;
}

.aspect-5-2 {
  aspect-ratio: 5/2;
}

.aspect-5-1 {
  aspect-ratio: 5/1;
}

.aspect-4-1 {
  aspect-ratio: 4/1;
}

.aspect-3-1 {
  aspect-ratio: 3/1;
}

.aspect-1-2 {
  aspect-ratio: 1/2;
}

.aspect-3-4 {
  aspect-ratio: 3/4;
}

.aspect-3-5 {
  aspect-ratio: 3/5;
}

.aspect-4-5 {
  aspect-ratio: 4/5;
}

.cs-info-color {
  color: $infoColor;
}

.sm-preview-img {
  height: 102px;
  width: 102px;

  .delete-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    border-radius: 5px;
    height: 20px;
    aspect-ratio: 1/1;
    @include flex-center-align;
    cursor: pointer;
    z-index: 99;
  }
}

@media (max-width: 768px) {
  .aspect-md-1-1 {
    aspect-ratio: 1/1;
  }

  .aspect-md-2-1 {
    aspect-ratio: 2/1;
  }

  .aspect-md-4-3 {
    aspect-ratio: 4/3;
  }

  .aspect-md-5-3 {
    aspect-ratio: 5/3;
  }

  .aspect-md-5-1 {
    aspect-ratio: 5/1;
  }

  .aspect-md-4-1 {
    aspect-ratio: 4/1;
  }

  .aspect-md-3-1 {
    aspect-ratio: 3/1;
  }

  .aspect-md-1-2 {
    aspect-ratio: 1/2;
  }

  .aspect-md-3-4 {
    aspect-ratio: 3/4;
  }

  .aspect-md-3-5 {
    aspect-ratio: 3/5;
  }

  .sm-preview-img {
    height: 75px;
    width: 75px;
  }
}

@media (max-width: 576px) {
  .sm-preview-img {
    height: 60px;
    width: 60px;
  }
}

.custom-big-preview-hover {
  overflow: hidden;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    color: var(--textColor);
    // -webkit-text-stroke: 0.5px $secondaryColor;
    font-weight: 600;
    text-align: center;
    transition: 0.3s;
    z-index: 4;
    background-color: $secondaryColor;
    border-radius: 0.5rem;
    padding: 0.5rem;
    min-width: 60%;
    outline: 3px solid var(--textColor);
    text-shadow: 0 0 1px black;
  }

  a {
    z-index: 5;
  }

  &:hover span {
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
}

.main-loader {
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: white;

  video {
    position: absolute;
    width: 50%;
    max-width: 300px;
    aspect-ratio: 1/1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
}

.custom-date-range-box {
  border: 1px solid $borderColor;

  input {
    &:focus {
      outline: 0px;
      box-shadow: 0 0 0 transparent;
    }
  }
}

.cs-border-danger {
  border-color: red !important;
}

.object-cover {
  object-fit: cover;
}

.object-contain {
  object-fit: contain;
}

.cs-temp-btn {
  @include tempBtn;
}

.first-cap {
  &::first-letter {
    text-transform: uppercase;
  }
}

.google-title {
  letter-spacing: -0.00833em;
  font-weight: 450;
  line-height: 1.5rem;
  color: #005bd3;
  font-family: "Inter";
}

.new-p-color {
  color: #616161;
}

.cursor-pointer{
  cursor: pointer;
}

.branding,
.seller-return-setting {
  .slider {
    background-color: #ffffff2b;
    border-radius: 100px;
    padding: 1px;
    cursor: pointer;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    align-items: center;
    position: relative;
    display: block;
    width: 50px;
    height: 25px;
    box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset,
      rgba(0, 0, 0, 0.21) 0px 0px 0px 24px inset, #22cc3f 0px 0px 0px 0px inset,
      rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
  }

  .slider::after {
    content: "";
    display: flex;
    top: 48%;
    transform: translateY(-50%);
    left: 3px;
    width: 20px;
    height: 20px;
    background-color: #e3e3e3;
    border-radius: 200px;
    position: absolute;
    box-shadow: transparent 0px 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 6px 6px;
    transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
      background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    will-change: left, background-color;
  }

  .switch input[type="checkbox"]:checked + .slider {
    box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset,
      #22cc3f 0px 0px 0px 2px inset, #22cc3f 0px 0px 0px 24px inset,
      rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
  }

  .switch input[type="checkbox"]:checked + .slider::after {
    left: 25px;
  }

  .switch input[type="checkbox"] {
    display: none;
  }
}

// block-panel script
.block-panel {
  height: 100vh !important;

  > *:not(.block-box) {
    z-index: 111;
    opacity: 0.2 !important;
    cursor: none !important;
    pointer-events: none !important;
  }

  .block-box {
    z-index: 9999 !important;
    opacity: 1 !important;

    .popup {
      display: flex;
      position: fixed;
      top: 35%;
      left: 40%;
      transform: translate(-50%, -50%);
      background: linear-gradient(145deg, #6f7efc, #4e58e1);
      border-radius: 20px;
      padding: 40px 50px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      text-align: center;
      z-index: 1000;
      width: 400px;
      animation: popup-fade 0.3s ease-in-out;
      // pointer-events: none;
      transition: transform 0.3s ease, opacity 0.3s ease-in-out;
      transform: scale(0.9);
      opacity: 0;
    }

    .popup.show {
      transform: scale(1);
      opacity: 1;
    }

    .popup h2 {
      font-size: 28px;
      font-family: "Roboto", sans-serif;
      color: white;
      margin-bottom: 15px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .popup p {
      font-size: 16px;
      color: #f1f1f1;
      margin-bottom: 20px;
    }

    .waitlist-info {
      font-size: 20px;
      font-weight: 600;
      color: #ffd700;
      margin-bottom: 20px;
      letter-spacing: 1px;
    }

    @keyframes popup-fade {
      0% {
        opacity: 0;
        transform: scale(0.9);
      }

      100% {
        opacity: 1;
        transform: scale(1);
      }
    }

    .popup p,
    .waitlist-info {
      transition: transform 0.2s ease, color 0.3s ease;
    }

    .popup p:hover {
      transform: translateY(-2px);
    }

    .waitlist-info:hover {
      color: #ffcc00;
    }
  }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .block-box .popup {
    padding: 15px 20px;
    top: 25%;
    left: 5%;
  }
}

@media (max-width: 480px) {
  .block-box .popup {
    padding: 10px 15px;
    top: 20% !important;
    left: 5% !important;
    width: 90% !important;
    padding: 20px 30px !important;
  }
}

.bg-success {
  background-color: $tempColor !important;
}

.text-success {
  color: $tempColor !important;
}

.bg-light-danger {
  background-color: #ff000033 !important;
}

/* Remove arrows in Chrome, Edge, and Safari */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.list-img {
  width: 90px !important;
  height: 90px !important;
}

.rounded-inherit {
  border-radius: inherit !important;
}

.min-img-h-50px {
  height: 50px !important;
}

.custom-hover-remove-btn {
  &:hover {
    background-color: transparent;
    border: 1px solid $borderColor;
  }
}

.first-capitalize::first-letter,
.text-first-capitalize::first-letter {
  text-transform: capitalize;
}

.seller-store-setting-container {
  .nav-item {
    .disabled {
      background-color: #e9ecef !important;
    }
  }
}

.new-product-detail-slide {
  .slick-track {
    margin-left: unset !important;
    margin-right: unset !important;
  }
}
.rti--container {
  border-radius: 0px !important;
}

.skeletop-card {
  .fpo {
    position: relative;
    margin: 25% auto;
    display: block;
  }
  .pulsate {
    background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
    background-size: 400% 400%;
    -webkit-animation: Gradient 2.25s ease infinite;
    -moz-animation: Gradient 2.25s ease infinite;
    animation: Gradient 2.25s ease infinite;
  }
  .block {
    display: block;
    width: 100%;
    height: 16px;
    color: black;
  }
  .block2 {
    width: 100%;
    height: 8px;
    margin-bottom: 8px;
  }

  .block {
    height: 8px;
  }

  .block3 {
    width: 50%;
    height: 8px;
    margin-bottom: 16px;
  }

  .block5 {
    width: 54%;
    height: 12px;
    margin-bottom: 16px;
  }

  .circle {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    float: right;
  }
  #card {
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    position: relative;
    margin: auto;
    top: 25%;
  }
  .card-image {
    box-sizing: border-box;
    display: block;
    width: 100%;
    aspect-ratio: 1/1;
    background: #fafafa;
    padding: 16px;
    border-radius: .5rem;
  }
  .card-content {
    clear: both;
    box-sizing: border-box;
    padding: 16px 0px;
    background: #fff;
  }
  @-webkit-keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @-moz-keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}


.custom-card-shadow{
  box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.5), inset 2px 2px 5px rgba(165, 165, 165, 0.25);
  // box-shadow: 0px 0px 3px rgba(128, 128, 128, 0.5);
}

.custom-product-list-dropdown{
  max-height: 35vh;
  overflow-y: scroll;
}

.product-search-input {
      border: 1px solid $intensDarkColor;
      border-radius: 1.5rem;
      background-color: $secondaryColor;
      transition: .3s;
      position: relative;
      padding-right: 0 !important;

      input {
          background-color: transparent;
          border: 0;
          width: 100%;

          &:focus {
              outline: 0;
          }
      }

      .custom-close-btn {
          cursor: pointer;
          // background-color: red;
          border-radius: 50%;
          padding: 0.25rem .25rem;
          @include flex-center-align;
          transition: .3s;

          &:hover {
              background-color: $borderColor;
          }
      }

      .custom-search-btn {
          right: 0;
          top: 0;
          height: 100%;
          padding: 0.5rem 1rem;
          border-radius: 0 2rem 2rem 0;
          background-color: $intensDarkColor;
          color: $secondaryColor;
          border: 1px solid $intensDarkColor;
          letter-spacing: 1px;
          text-decoration: none;
      }
}

.custom-product-tabs{

  .nav-pills .nav-link{
    border-radius: .5rem .5rem 0rem 0rem;
    color: black;
  }

  .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: $tempColor !important;
    color: white;
  }

}

.custom-mockup-style{
  .spinner-grow{
    height: 1rem;
    width: 1rem;
    aspect-ratio: 1/1;
  }
}

.custom-img-bg{
  height: 100vh;
  background-size: cover;
}

.css-1nmdiq5-menu{
  height: 228px !important;
  overflow-y: hidden;
}
.custom-font-list{
  .css-1u9des2-indicatorSeparator,
  .css-1xc3v61-indicatorContainer {
    display: none;
  }
  .css-1n6sfyn-MenuList, #react-select-5-listbox, #react-select-2-listbox{
    div{
      background-color: transparent;
      color: black;
    }
  }
}

.search-input-clipart {
  width: 100%;
  // max-width: 220px;
  height: 45px;
  padding: 12px;
  border-radius: 12px;
  border: 1.5px solid lightgrey;
  outline: none;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0px 0px 20px -18px;
}

.search-input-clipart:hover {
  border: 2px solid lightgrey;
  box-shadow: 0px 0px 20px -17px;
}

.search-input-clipart:active {
  transform: scale(0.95);
}

.search-input-clipart:focus {
  border: 2px solid grey;
}

.transparent-bg-img{
  // background-image: url('../Images/transparent-bg-img.png');
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-img-holder-container-temp {

  .custom-img-holder {
      background-color: var(--imgBack);
      width: 100%;
      aspect-ratio: 1 / 1;
      overflow: hidden;
      border-radius: var(--productBorderRadius);
      display: flex;
      justify-content: center;
      align-items: center;
      border: var(--productBorderWidth) var(--productBorderStyle) var(--productBorderColor);
      padding: var(--productImagePadding);
      box-shadow: var(--productBoxShadowX) var(--productBoxShadowY) var(--productBoxShadowBlur) var(--productBoxShadowColor);

      a {
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
          border-radius: var(--productImageBorderRadius);

          img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              position: absolute;
              top: 0;
              left: 0;
              transition: transform 0.45s ease;
          }

          .img-one {
              transform: translateX(0);
              /* Initially visible */
              z-index: 1;
          }

          .img-two {
              transform: translateX(100%);
              /* Positioned off-screen to the right */
              z-index: 2;
          }
      }
  }

  .custom-img-cnt {
      color: var(--bodyTextColor);
      text-align: var(--productTextAlign);

      a {
          color: var(--bodyTextColor);
          transition: .3s;
          text-decoration: none;

          &:hover {
              color: var(--contrastColor);
              text-decoration: underline;
          }
      }
  }

}