@import '_var';
@import '_mixins';

:root {
    --sidebar-width-margin: 13%;
    --collapsed-sidebar-width-margin: 75px;
}

.dashboard-panel-sidebar {
    width: var(--sidebar-width-margin);
    border-right: 1px solid $tempColor;
    position: fixed;
    transition: width 0.3s ease;
    z-index: 999;
}

.dashboard-panel-content {
    margin-left: var(--sidebar-width-margin);
    width: 100%;
    max-width: 87%;
    transition: margin-left 0.3s ease;
    min-height: 100vh;

    .container {
        max-width: 1134px !important;
    }
}

.is-collapsed .dashboard-panel-sidebar {
    width: var(--collapsed-sidebar-width-margin);
    width: var(--collapsed-sidebar-width-margin);
}

.is-collapsed .dashboard-panel-content {
    margin-left: var(--collapsed-sidebar-width-margin);
    max-width: 100%;
}

.custom-rating {
    background-color: $sellerThemeColor;

    .rating-img-holder {
        width: 60px;
        aspect-ratio: 1/1;
        overflow: hidden;
        border-radius: 50%;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

@media(max-width: 992px) {
    .dashboard-panel-sidebar {
        width: 100% !important;
        position: fixed;
        border-right: 0px solid $themeColor;
        top: 0;
        z-index: 99999;
    }

    .dashboard-panel-content {
        margin-top: 75px;
        margin-left: 0px;
        width: 100%;
        max-width: 100%;
    }
}

.seller-bg-grey {
    background-color: $sellerThemeColor;
}